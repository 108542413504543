import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import BGHero from "../components/heros/bgHero"
import RightIconGrid from "../components/features/rightIconGrid"
import CenterCTA from "../components/ctas/centerCTA"
import CardCenterStats from "../components/stats/cardCenterStats"
import BGStats from "../components/stats/bgStats"
import AccordianFAQs from "../components/faqs/accordianFAQs"
import SinglePricing from "../components/pricing/singlePricing"
import TwoToneSplitContact from "../components/contact/twoToneSplitContact"
import Dark4ColFooter from "../components/footers/dark4ColFooter"

const Color = {
  pallet: "green"
}

const ThirdPage = () => (
  <div>
      <BGHero />
      <RightIconGrid />
      <CardCenterStats />
      <CenterCTA />
      <BGStats />
      <AccordianFAQs />
      <SinglePricing />
      <TwoToneSplitContact />
      <Dark4ColFooter />

      
</div>

)

export default ThirdPage
